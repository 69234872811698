import { CbhFeatureFlag, FeatureFlagVariants } from "@src/appV2/FeatureFlags";
import { calculateGeoDistanceInMiles, convertToGeoLocation } from "@src/appV2/Location";
import { Agent, Shift, VerificationPreferences } from "@src/lib/interface";
import moment from "moment-timezone";

import { simplePlural } from "./strings";

/**
 * returns true, if second argument shift start and end date
 * does not conflict with shifts passed.
 */
export const isNonConflictingShift = (shifts: Shift[], shift: Shift): boolean => {
  const shiftStart = shift.start as string;
  const shiftEnd = shift.end as string;
  return shifts.every((myShift) => {
    const myShiftStart = myShift.start as string;
    const myShiftEnd = myShift.end as string;
    return (
      (shiftStart <= myShiftStart && shiftEnd <= myShiftStart) ||
      (shiftStart >= myShiftEnd && shiftEnd >= myShiftEnd)
    );
  });
};

/**
 *
 * @param openShifts removed conflicting shifts from this shift.
 * @param myShifts already assigned shifts.
 */
export const removeConflictingShifts = (openShifts: Shift[], myShifts: Shift[]): Shift[] =>
  openShifts.filter((shift) => isNonConflictingShift(myShifts, shift));

export const addVerificationPreferencesToShiftFacility = (
  shifts: Shift[],
  facilitiesVerificationPreferences: VerificationPreferences[] | undefined = []
) => {
  const facilitiesById = facilitiesVerificationPreferences.reduce(
    (acc, current) => ({ ...acc, [current.mongodbId]: current }),
    {}
  );
  return shifts.map((shift) => ({
    ...shift,
    ...(shift.facility?.userId
      ? {
          facility: {
            ...shift.facility,
            verificationPreference: facilitiesById[shift.facility.userId],
          },
        }
      : {}),
  }));
};

export const checkRolloutMsa = (shift: Shift, rolloutMsas: string[]): boolean => {
  return rolloutMsas.includes(shift.facility?.fullAddress?.metropolitanStatisticalArea as string);
};

export const doesShiftIncludeLunchBreak = (
  start: string | undefined,
  end: string | undefined,
  duration?: number
) => {
  if (duration && duration > 0) {
    return duration > 6;
  }
  if (!start || !end) {
    return true;
  }
  const nonLunchDuration = moment.duration(moment(end).diff(moment(start))).asHours();
  return nonLunchDuration > 6;
};

export const requiredLunchBreakDuration = (shift: Shift) => {
  let requiresLunchBreak = shift.facility?.verificationPreference?.requiresLunchBreak ?? false;
  let lunchBreakDuration = "";

  if (doesShiftIncludeLunchBreak(shift.start, shift.end)) {
    lunchBreakDuration = "30 minute";
  } else {
    requiresLunchBreak = false;
  }
  return [requiresLunchBreak, lunchBreakDuration];
};

export const getNonIPAmount = (shift: Shift, ldFlags: FeatureFlagVariants): number => {
  if (ldFlags[CbhFeatureFlag.USE_ORIGINALAMOUNT_FIELD_NON_IP]) {
    return shift.originalAmount!;
  }
  return shift.finalPay! * shift.time!;
};

export const isShiftPriorityValid = (priorityTill: string) =>
  moment(priorityTill).isAfter(moment.utc());

export const formatShiftDurationByDifference = (
  start: string,
  end: string,
  facilityTimezone: string
): string => {
  const hoursString = formatShitStartAndEnd(start, end, facilityTimezone);

  const shiftStart = moment(start).tz(facilityTimezone);
  const shiftEnd = moment(end).tz(facilityTimezone);
  const nonLunchDuration = moment.duration(shiftEnd.diff(shiftStart)).asHours();
  const durationInHours = doesShiftIncludeLunchBreak(start, end, nonLunchDuration)
    ? nonLunchDuration - 0.5
    : nonLunchDuration;
  const hours = Math.trunc(durationInHours);
  const minutes = Math.trunc((durationInHours - hours) * 60);
  const durationString = `${hours} ${simplePlural("hr", hours)}${
    minutes ? ` ${minutes} ${simplePlural("min", minutes)}` : ""
  }`;

  return `${hoursString} (${durationString})`;
};

const formatShitStartAndEnd = (start: string, end: string, facilityTimezone: string) => {
  const shiftStart = moment(start).tz(facilityTimezone);
  const shiftEnd = moment(end).tz(facilityTimezone);
  return `${shiftStart.format("hh:mm A")} - ${shiftEnd.format("hh:mm A")}`;
};

export const getShiftFacilityDistanceText = (
  shift: Shift,
  agent: Agent,
  isUrgentShift: boolean
): string => {
  const facilityGeoLocation = convertToGeoLocation(
    shift.facility?.geoLocation?.coordinates ?? [0, 0]
  );
  const agentGeoLocation = convertToGeoLocation(agent?.geoLocation?.coordinates ?? [0, 0]);
  let isApproxDistance: boolean;
  let distance: number;
  if (shift.distance) {
    ({ distance } = shift);
    isApproxDistance = shift.isApproxDistance ?? false;
  } else if (shift.facility?.distance) {
    distance = shift.facility?.distance;
    isApproxDistance = shift.facility?.isApproxDistance ?? false;
  } else {
    distance = calculateGeoDistanceInMiles(agentGeoLocation, facilityGeoLocation);
    isApproxDistance = true;
  }
  if (isApproxDistance) {
    distance = Math.round(2 * distance) / 2;
  }

  const distFrom = isUrgentShift ? "away from you" : "from home";
  const isApproxDistanceText = isApproxDistance ? "~" : "";
  if (!distance) {
    return "";
  }
  if (distance < 1) {
    return `${isApproxDistanceText}${distance.toFixed(1)} miles ${distFrom}`;
  }
  if (distance === 1) {
    return `${isApproxDistanceText}${distance} mile ${distFrom}`;
  }
  return `${isApproxDistanceText}${distance.toFixed(1)} miles ${distFrom}`;
};
